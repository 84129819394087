import React from "react"
import { Link } from "gatsby"
import "./layout.css";
import logo_firmy from "../images/logo-lostit.svg"




const zm_logo_firmy = <img src={logo_firmy} alt="Logo" class="aaa_logo_firmy"  width="400px" />;

export default ({ children }) => (
  <div style={{margin: `1rem auto`, height: 1000, maxWidth: 800, padding: `0 1rem` }}>
    <header>
      <Link to="/" >
        {zm_logo_firmy} 
        
        <h1>obsługa informatyczna firm</h1> 
        </Link>
        <h2>zadzwoń: 794-042-789</h2>
      
     
         
    </header>
 
    {children}


    <footer>
    <div class="aaa_linia_dolna"> </div>
            
            
            <div class="aaa_kolumny">
              <div class="aaa_stopka">
                LOST-IT Artur Losa<br />
                41-700 Ruda Śląska<br />
                ul. Cypriana Norwida 19 lok 12<br />
                nip: 641-216-64-86<br />
              </div>
              <div class="aaa_stopka">
                <a href="mailto:info@lost-it.pl">email: info@lost-it.pl</a><br />
                pon. – pt. 7:00 – 16:00<br />
                <Link to="rodo" >rodo: obowiązek informacyjny</Link><br />
                ING: PL 53 1050 1331 1000 0090 9484 5857
              </div>

            </div>
            <a href="http://lost-it.pl" class="aaa_wykonanie">www.lost-it.pl @ 2019</a>
          </footer>

  </div>
)