import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"






const IndexPage = () => (
  <Layout>
    <SEO title="LOST-IT obsługa informatyczna firm" keywords={[`LOST-IT`, `obsługa informatyczna firm`, `serwis`, `komputery`, `oprogramowanie`, `archiwizacja`, `backup`]} />

    <Link to="wiecej" >
      <span class="aaa_dot"></span><span class="aaa_dot"></span><span class="aaa_dot3"></span>
    </Link>
    
  </Layout>
)

export default IndexPage
